export const GERMAN = {
    header: {
        accountSetting: 'Einstellungen',
        accountSettings: 'Einstellungen',
        allCards: 'Alle Karten',
        newOrders: 'Neue Bestellungen',
        signedInAs: 'Eingeloggt als',
        signOut: 'Abmelden',
        visitWebsite: 'Besuchen Sie Website'
    },
    common: {
        about: 'Über uns',
        appearance: 'Erscheinung',
        allCards: 'Alle Karten',
        cancel: 'Löschen',
        cardViews: 'Karten Ansichten',
        changePassword: 'Kennwort ändern',
        changesSaved: 'Änderungen gespeichert',
        close: 'Schließen',
        color: 'Farbe',
        companyLogo: 'Firmenlogo',
        contact: 'Kontakt',
        contacts: 'Kontakte',
        contactSaved: 'Kontakt gespeichert',
        copy: 'Kopieren',
        copied: 'Kopiert!',
        createOrder: 'Ordnung schaffen',
        currentPassword: 'Aktuelles Passwort',
        dataAndPrivacy: 'Daten und Privatsphäre',
        deleteCard: 'Karte löschen',
        deletePermanently: 'Endgültig löschen',
        dismiss: 'Zurückweisen',
        download: 'Herunterladen',
        edit: 'Bearbeiten',
        editCard: 'Karte bearbeiten',
        email: 'Email',
        emailDash: 'E-mail',
        emailAddress: 'E-Mail-Adresse',
        facebook: 'Facebook',
        general: 'Allgemein',
        instagram: 'Instagram',
        language: 'Sprache',
        leave: 'Verlassen',
        links: 'Links',
        linkedin: 'Linkedin',
        message: 'Nachricht',
        name: 'Name',
        newPassword: 'Neues Passwort',
        noFileChosen: 'Keine Datei ausgewählt',
        notifications: 'Benachrichtigungen',
        others: 'Andere',
        password: 'Passwort',
        personalized: 'Personalisiert',
        phone: 'Telefonnummer',
        previewCard: 'Vorschau',
        pleaseSelectOption : 'Please select an option',
        qrCode: 'QR code',
        refreshPage : 'Bitte aktualisieren Sie die Seite, um fortzufahren',
        reload: 'Neu laden',
        remove: 'Entfernen',
        save: 'Speichern',
        saveChanges: 'Änderungen speichern',
        saveContact: 'Kontakt speichern',
        search: 'Suchen',
        selectOption: 'Option auswählen',
        selectPlatform: 'Plattform auswählen',
        send: 'Schicken',
        sent: 'Gesendet',
        sessionExpired: 'Sitzung abgelaufen',
        setInactive: 'Deaktivieren',
        share: 'Teilen',
        shareCard: 'Karte teilen',
        stay: 'Bleiben',
        subject: 'Objekt',
        subjectLine: 'Die Betreffzeile',
        support: 'Unterstützung',
        today: 'Heute',
        tiktok: 'Tiktok',
        title: 'Titel',
        to: 'Zu',
        upload: 'Hochladen',
        uploadLogo: 'Hochladen Logo',
        yourAccount: 'Deine Konto',
        website: 'Webseite',
        whatsapp: 'WhatsApp',
        x: 'X',
        yourBusinessCard: 'Deine Visitenkarte'
    },
    labels: {
        cardProduct: 'Kartenprodukt',
        choose: 'Wählen',
        enterUuid: 'Enter UUID',
        position: 'Position',
        qrCodeSize: 'QR-Größe',
        searchByOrderIdOrCardHolderBuyer: 'Suchen Sie nach Bestell-ID oder Karteninhaber/Käufer',
    },
    modal: {
        areYouSure: 'Bist du sicher?',
        assignProductText: 'Der Produkttyp {{product}} ist der Karten-ID {{cardId}} zugeordnet',
        assignUuidText: 'UUID {{uuid}} wird der Zahlungs-ID {{paymentId}} zugewiesen',
    },
    qrCode: {
        uuidGeneratorDesc: 'By pressing the button, NEW UUIDs and Card profiles will be generated (stored in the database) and you will get a zip file of the QR code images.',
        uuidGeneratorWarningDesc: 'USE this section ONLY if you want to print generated QR code images onto physical cards!',
        uuidGeneratorSuccessDesc: 'The NEW QR code images and Card profiles are generated!',
        uuidBatchGeneratorDesc: 'Use this section to generate QR code images for the EXISTING UUIDs, note that this section will not create a new UUIDs (will not store it in the database)'
    }
};