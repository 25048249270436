import axiosInstance from './axiosInstance';
import store from '../store/store';

export const configDefault = async () => {
    return {
        headers: {
            'Access-Control-Allow-Origin': '*',
            credentials: 'same-origin',
        }
    };
};

export const configForImages = async () => {
    const token = store.getState().userSession.token;

    return {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type':'multipart/form-data',
            'X-XSS-Protection': '1; mode=block',
            'Access-Control-Allow-Origin': '*',
            credentials: 'same-origin',
        }
    };
};

export const configDefaultWithToken = async () => {
    const token = store.getState().userSession.token;

    return {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            credentials: 'same-origin'
        }
    };
};


export const getApiCall = async (url: string, config: any) => {
    return getApiResponse(axiosInstance.get(url, config));
};

export const postApiCall = async (url: string, data: any, config: any) => {
    return getApiResponse(axiosInstance.post(url, data, config));
};

export const putApiCall = async (url: string, data: any, config: any) => {
    return getApiResponse(axiosInstance.put(url, data, config));
};

export const patchApiCall = async (url: string, data: any, config: any) => {
    return getApiResponse(axiosInstance.patch(url, data, config));
};

export const deleteApiCall = async (url: string, config: any) => {
    return getApiResponse(axiosInstance.delete(url, config));
};

export const getApiResponse = async (request: Promise<any>) => {
    const response: { data: any; error: any; errorMsg: any, status: number } = {
        data: {},
        error: null,
        errorMsg: null,
        status: 0,
    };

    await request
        .then((res) => {
            response.data = res.data;
            response.status = res.status;
        })
        .catch((error) => {
            response.error = error;
            response.errorMsg = error.response?.data?.error;
            response.status = error.response?.status;
        });

    return response;
};

export const getMultipleApiCall = async (urls: string[], useToken: boolean) => {
    const response: { data: any; error: any; status: number } = {
        data: {},
        error: null,
        status: 0,
    };

    const promises : any = urls.map(async (url) =>
        axiosInstance.get(url, {
            ...await (useToken ? configDefaultWithToken() : configDefault()),
        })
    );

    await Promise.allSettled(promises)
        .then((results) => {
            const data: any = {};

            results.forEach((result, index) => {
                if (result.status === 'fulfilled') {
                    const res = result.value;
                    data[urls[index]] = {
                        data: res.data,
                        headers: res.headers,
                    };
                } else {
                    console.log('Request failed:', result.reason);
                }
            });

            response.data = data;
            return data;
        })
        .catch((error) => {
            response.error = error.message;
            response.status = error.response?.status;
            return response;
        });

    return response;
};
