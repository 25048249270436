import {Card, CardPagination} from '../types/card';
import {isEmpty} from '../utils/common';
import {getDateForOrderTable} from '../utils/date';


export const CardConverter = {
    convertFromCardApiToCardPagination(data: any): CardPagination | undefined {
        if (!data) return undefined;
        const content = data.content;

        return {
            cards: CardConverter.convertFromCardApiToCards(content),
            size: data.size,
            totalElements: data.totalElements ?? 0,
            totalPages: data.totalPages
        };
    },
    convertFromCardApiToCards(data: any[]): Card[] {
        if (isEmpty(data)) return [];

        const cards: Card[] = [];

        data.forEach(d => {
            cards.push(CardConverter.convertFromCardApiToCard(d));
        });

        return cards;
    },
    convertFromCardApiToCard(data: any): Card {
        return {
            id: data.id,
            profileId: data.cardProfileId,
            orderId: data.orderId,
            cardBuyer: data.cardBuyer,
            cardHolder: data.cardHolder,
            product: data.cardProduct,
            created: getDateForOrderTable(data.created),
            status: data.status,
            uuid: data.uuid
        };
    }
};