export const FRENCH = {
    header: {
        accountSetting: 'Paramètre du compte',
        accountSettings: 'Paramètres du compte',
        allCards: 'Toutes les cartes',
        newOrders: 'Nouvel ordre',
        signedInAs: 'Connecté en tant que',
        signOut: 'Se déconnecter',
        visitWebsite: 'Visitez Web'
    },
    common: {
        about: 'À propos',
        appearance: 'Apparence',
        allCards: 'Toutes les cartes',
        cancel: 'Annuler',
        cardViews: 'Vues de cartes',
        changePassword: 'Changer le mot de passe',
        changesSaved: 'Enregistrées',
        close: 'Fermer',
        color: 'Couleur',
        companyLogo: 'logo d\'entreprise',
        contact: 'Contact',
        contacts: 'Contacts',
        contactSaved: 'Contact enregistré',
        copy: 'Copie',
        copied: 'Copié!',
        createOrder: 'Créer une commande',
        currentPassword: 'Mot de passe actuel',
        dataAndPrivacy: 'Données et confidentialité',
        deleteCard: 'Supprimer la carte',
        deletePermanently: 'Supprimer définitivement',
        dismiss: 'Rejeter',
        download: 'Télécharger',
        edit: 'Modifier',
        editCard: 'Modifier la carte',
        email: 'Email',
        emailDash: 'E-mail',
        emailAddress: 'Adresse e-mail',
        facebook: 'Facebook',
        general: 'Généralités',
        instagram: 'Instagram',
        language: 'Langue',
        leave: 'Partir',
        links: 'Liens',
        linkedin: 'Linkedin',
        message: 'Message',
        name: 'Nom',
        newPassword: 'Nouveau mot de passe',
        noFileChosen: 'Aucun fichier choisi',
        notifications: 'Notifications',
        others: 'Autres',
        password: 'Mot de passe',
        personalized: 'Personnalisé',
        phone: 'Téléphone',
        previewCard: 'Aperçu de la carte',
        pleaseSelectOption: 'Veuillez sélectionner une option',
        qrCode: 'QR code',
        refreshPage: 'Veuillez rafraîchir la page pour continuer',
        reload: 'Recharger',
        remove: 'Supprimer',
        save: 'Enregistrer',
        saveChanges: 'Enregistrer les modifications',
        saveContact: 'Enregistrer le contact',
        search: 'Rechercher',
        selectOption: 'Sélectionner une option',
        selectPlatform: 'Sélectionner une plateforme',
        send: 'Envoyer',
        sent: 'Envoyée',
        sessionExpired: 'Session expirée',
        setInactive: 'Définir comme inactif',
        share: 'Partager',
        shareCard: 'Partager la carte',
        stay: 'Rester',
        subject: 'Objet',
        subjectLine: 'Ligne d\'objet',
        support: 'Soutien',
        today: 'Aujourd\'hui',
        tiktok: 'Tiktok',
        title: 'Titre',
        to: 'À',
        upload: 'Télécharger',
        uploadLogo: 'Télécharger Logo',
        yourAccount: 'Votre compte',
        website: 'Site web',
        whatsapp: 'WhatsApp',
        x: 'X',
        yourBusinessCard: 'Votre carte de visite'
    },
    labels: {
        cardProduct: 'Produit de carte',
        choose: 'Choisir',
        enterUuid: 'Enter UUID',
        position: 'Poste',
        qrCodeSize: 'QR taille',
        searchByOrderIdOrCardHolderBuyer: 'Recherche par numéro de commande ou titulaire de carte/acheteur',
    },
    modal: {
        areYouSure: 'Êtes-vous sûr ?',
        assignProductText: 'Le type de produit {{product}} sera attribué à CARD ID {{cardId}}',
        assignUuidText: 'UUID {{uuid}} sera attribué à Paiement ID {{paymentId}}',
    },
    qrCode: {
        uuidGeneratorDesc: 'By pressing the button, NEW UUIDs and Card profiles will be generated (stored in the database) and you will get a zip file of the QR code images.',
        uuidGeneratorWarningDesc: 'USE this section ONLY if you want to print generated QR code images onto physical cards!',
        uuidGeneratorSuccessDesc: 'The NEW QR code images and Card profiles are generated!',
        uuidBatchGeneratorDesc: 'Use this section to generate QR code images for the EXISTING UUIDs, note that this section will not create a new UUIDs (will not store it in the database)'
    }
};