import React from 'react';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import i18n from '../../translations/i18n';
import {QRCodeFilterSizes} from './Dashboard';
import {eyeBase64Image} from '../../constants/eyeBase64Image';
import {InputWithSubmit} from '../../components/input/InputWithSubmit';

export const getColumns = (
    onUuidSelect: (uuid: string, paymentId: number) => void,
    loadingSaveUuid: boolean
): GridColDef[] => [
    { field: 'id', headerName: 'Payment ID', type: 'number', width: 100 },
    { field: 'orderId', headerName: 'Order ID', type: 'number', width: 80 },
    { field: 'userEmail', headerName: 'User', type: 'string', width: 200 },
    {
        field: 'uuid',
        headerName: 'UUID',
        type: 'string',
        width: 200,
        renderCell: (params: GridRenderCellParams) => {
            if (params.row.uuid) {
                return params.row.uuid;
            } else {
                return (
                    <InputWithSubmit
                        placeholder={i18n.t('labels.enterUuid')}
                        onSave={(value: string) => onUuidSelect(value, params.row.id)}
                        loading={loadingSaveUuid}
                    />
                );
            }
        },
    },
    { field: 'cardProduct', headerName: 'Product', type: 'string', width: 130 },
    { field: 'color', headerName: 'Color', type: 'string', width: 90 },
    { field: 'logoUrl', headerName: 'Logo', type: 'string', width: 300 },
    { field: 'personalized', headerName: 'Personalized', type: 'boolean', width: 110 },
    { field: 'orderType', headerName: 'Order Type', type: 'string', width: 110 },
    { field: 'created', headerName: 'Created', type: 'string', width: 160 }

];

export const getQrCodeEyeSize = (qrcodeSize: QRCodeFilterSizes): string => {
    if (qrcodeSize === 300) return '63';
    if (qrcodeSize === 600) return '126';
    if (qrcodeSize === 1200) return '252';
    return '126';
};

export const getQrCodeEyeSizeCordinates = (qrcodeSize: QRCodeFilterSizes): {x: string, y: string} => {
    if (qrcodeSize === 300) return {x: '235', y: '1'};
    if (qrcodeSize === 600) return {x: '471', y: '3'};
    if (qrcodeSize === 1200) return {x: '942', y: '6'};
    return {x: '471', y: '3'};
};

export const getQrCodeEyeImage = (eyeImage: string, eyeSize: string, xCordinate: string, yCordinate: string) => {
    const img = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    img.setAttribute('x', xCordinate);
    img.setAttribute('y', yCordinate);
    img.setAttribute('width', eyeSize);
    img.setAttribute('height', eyeSize);
    img.setAttribute('href', eyeImage);

    return img;
};

export const replaceEyesWithImages = (svg: SVGSVGElement, qrCodeSize: QRCodeFilterSizes) => {
    const rect00 = svg.querySelector('rect[clip-path="url(\'#clip-path-corners-square-color-0-0\')"]');
    const rect01 = svg.querySelector('rect[clip-path="url(\'#clip-path-corners-square-color-0-1\')"]');
    const rect02 = svg.querySelector('rect[clip-path="url(\'#clip-path-corners-square-color-1-0\')"]');
    const eyeSize = getQrCodeEyeSize(qrCodeSize);
    const eyeCordinates = getQrCodeEyeSizeCordinates(qrCodeSize);
    const eyeImage = eyeBase64Image;

    if (rect00) {
        const customImage00 = getQrCodeEyeImage(eyeImage, eyeSize, eyeCordinates.y, eyeCordinates.y);
        rect00.parentNode?.replaceChild(customImage00, rect00);
    }

    if (rect01) {
        const customImage01 = getQrCodeEyeImage(eyeImage, eyeSize, eyeCordinates.y, eyeCordinates.x);
        rect01.parentNode?.replaceChild(customImage01, rect01);
    }

    if (rect02) {
        const customImage02 = getQrCodeEyeImage(eyeImage, eyeSize, eyeCordinates.x, eyeCordinates.y);
        rect02.parentNode?.replaceChild(customImage02, rect02);
    }
};