import {getCardProductsApi, getCardsApi} from '../../api/card';
import {useEffect, useState} from 'react';
import {selectUser} from '../../store/slices/userSessionSlice';
import {useAppSelector} from '../../store/appDispatch';
import {CARD_PRODUCTS, CardApiParams, CardPagination} from '../../types/card';
import {CardConverter} from '../../converters/CardConverter';
import {CARD_API_PAGE_SIZE} from '../../constants/common';

const defaultApiParams: CardApiParams = {
    page: 0,
    size: CARD_API_PAGE_SIZE
};

const useAllCardsController = () => {
    const user = useAppSelector(selectUser);
    const [apiParams, setApiParams] = useState<CardApiParams>(defaultApiParams);
    const [cardPagination, setCardPagination] = useState<CardPagination | undefined>(undefined);
    const [cardProducts, setCardProducts] = useState<CARD_PRODUCTS[]>([]);
    const [loading, setLoading] = useState<boolean>(false);


    useEffect(() => {
        if (user && !loading) {
            setLoading(true);
            getCardsApi(apiParams)
                .then(res => {
                    if (res.error) return;
                    setCardPagination(CardConverter.convertFromCardApiToCardPagination(res.data));
                })
                .finally(() => setLoading(false));
        }
    }, [user, apiParams]);

    useEffect(() => {
        if (user) {
            getCardProductsApi()
                .then(res =>  {
                    if (res.error) return;
                    setCardProducts(res.data);
                });
        }
    }, []);

    return {
        cardPagination,
        setApiParams,
        apiParams,
        loading,
        setCardPagination,
        cardProducts
    };
};

export default useAllCardsController;